import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { browserName, isMobile } from "react-device-detect";
import { ReducerData, ReducerGraphic, Reducers } from "../types/reducers";
import { usePrevious } from "../Utils/Functions";
import Loader from "./Loader/Loader";

interface Props {
  loadMore: () => void;
  children: any;
}

const InfiniteLoader: React.FC<Props> = (props) => {
  const graphic: ReducerGraphic = useSelector(
    (store: Reducers) => store.graphic
  );
  const data: ReducerData = useSelector((store: Reducers) => store.data);
  const previousFetchingState = usePrevious(graphic.isFetchingNews);
  const [reachedBottom, setReachedBottom] = useState(false);
  const previousReachedBottom = usePrevious(reachedBottom);

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);
    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  const isBottom = (el: any) => {
    return el.getBoundingClientRect().bottom - 100 <= window.innerHeight;
  };

  useEffect(() => {
    if (previousFetchingState !== undefined) {
      if (previousFetchingState == true && graphic.isFetchingNews == false) {
        document.addEventListener("scroll", trackScrolling);
      }
    }
  }, [graphic.isFetchingNews]);

  useEffect(() => {
    if (
      previousReachedBottom !== undefined &&
      previousReachedBottom == false &&
      reachedBottom == true
    ) {
      if (!isMobile && browserName === "Chrome" && data.news.length >= 100) {
        return;
      }
      props.loadMore();
    }
  }, [reachedBottom]);

  const trackScrolling = async () => {
    const wrappedElement = document.getElementById("scroller");
    if (isBottom(wrappedElement)) {
      document.removeEventListener("scroll", trackScrolling);
      setReachedBottom(true);
    } else {
      setReachedBottom(false);
    }
  };

  return (
    <div id="scroller">
      {props.children}
      {graphic.isFetchingNews && <Loader />}
    </div>
  );
};

export default InfiniteLoader;
