import { GRAPHIC_ACTIONS } from "./../actions/graphic";
import { ReduxActionData, ReducerGraphic } from "../../types/reducers";

const defaultValue: ReducerGraphic = {
  currentPage: 1,
  offset: 0,
  isLoadingRegions: true,
  isLoadingSources: true,
  isLoadingTags: true,
  isLoadingNews: true,
  isFetchingNews: false,
};

const graphicState: (
  state: ReducerGraphic,
  actions: ReduxActionData<any>
) => ReducerGraphic = (state = defaultValue, action: ReduxActionData<any>) => {
  switch (action.type) {
    case GRAPHIC_ACTIONS.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case GRAPHIC_ACTIONS.SET_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case GRAPHIC_ACTIONS.SET_LOADING_REGIONS:
      return {
        ...state,
        isLoadingRegions: action.payload,
      };
    case GRAPHIC_ACTIONS.SET_LOADING_TAGS:
      return {
        ...state,
        isLoadingTags: action.payload,
      };
    case GRAPHIC_ACTIONS.SET_LOADING_SOURCES:
      return {
        ...state,
        isLoadingSources: action.payload,
      };
    case GRAPHIC_ACTIONS.SET_FETCHING_NEWS:
      return {
        ...state,
        isFetchingNews: action.payload,
      };
    default:
      return state;
  }
};

export default graphicState;
