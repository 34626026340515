import { ReduxActionData } from "./../../types/reducers";
import { ReducerUser } from "../../types/reducers";
import { USER_ACTIONS } from "../actions/user";

const defaultValue: ReducerUser = {
  selectedQuery: "",
  selectedSources: [],
  selectedRegions: [],
  selectedTags: [],
};

const userState: (
  state: ReducerUser,
  actions: ReduxActionData<any>
) => ReducerUser = (state = defaultValue, action: ReduxActionData<any>) => {
  switch (action.type) {
    case USER_ACTIONS.SET_SELECTED_QUERY:
      return {
        ...state,
        selectedQuery: action.payload,
      };
    case USER_ACTIONS.SET_SELECTED_SOURCES:
      return {
        ...state,
        selectedSources: action.payload,
      };
    case USER_ACTIONS.SET_SELECTED_REGIONS:
      return {
        ...state,
        selectedRegions: action.payload,
      };
    case USER_ACTIONS.SET_SELECTED_TAGS:
      return {
        ...state,
        selectedTags: action.payload,
      };
    case USER_ACTIONS.CLEAN_FILTER:
      return defaultValue;
    default:
      return state;
  }
};

export default userState;
