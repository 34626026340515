import React, { useState } from 'react'
import { FilterType } from '../Utils/enum/FilterType'
import { Search, Subject, LocationOn, Label } from '@material-ui/icons';
import { ReducerGraphic, Reducers, ReducerUser } from '../types/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { cleanFilter, setSelectedQuery } from '../store/actions/user';
import ModalFilter from './ModalFilter'
import { isMobile } from 'react-device-detect';
import LoadingIcon from './Loader/LoadingIcon';

interface Props {
    hideBar: () => void
}

const FilterBar: React.FC<Props> = (props) => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()
    const [typeFilter, setTypeFilter] = useState<FilterType | null>(null)
    const [querySearch, setQuerySearch] = useState(user.selectedQuery)

    //console.log(graphic)

    return (
        <div className="filterContainer">
            <ModalFilter type={typeFilter} onClose={() => setTypeFilter(null)} />
            <div className="searchQueryContainer">
                <Search className="iconFilter" />
                <input value={querySearch} placeholder="Cerca..." onChange={(e) => setQuerySearch(e.target.value)} className="searchQueryInput" />
                {querySearch && querySearch.length >= 3 && (<span className="filterButton" onClick={() => { dispatch(setSelectedQuery(querySearch)) }}>Filtra</span>)}
            </div>
            <div
                className="selectedSourcesContainer filterItem"
                onClick={() => {
                    if (graphic.isLoadingSources) {
                        return
                    }
                    setTypeFilter(FilterType.SOURCE)
                }}
            >
                <Subject className="iconFilter" />
                <p>Testate {user.selectedSources && user.selectedSources.length > 0 && "(selezionate: " + user.selectedSources.length + ")"}</p>
                {graphic.isLoadingSources && (
                    <div className="loaderIcon">
                        <LoadingIcon />
                    </div>
                )}
            </div>
            <div
                className="selectedRegionsContainer filterItem"
                onClick={() => {
                    if (graphic.isLoadingRegions) {
                        return
                    }
                    setTypeFilter(FilterType.REGION)
                }}
            >
                <LocationOn className="iconFilter" />
                <p>Regioni {user.selectedRegions && user.selectedRegions.length > 0 && "(selezionate: " + user.selectedRegions.length + ")"}</p>
                {graphic.isLoadingRegions && (
                    <div className="loaderIcon">
                        <LoadingIcon />
                    </div>
                )}
            </div>
            <div
                className="selectedTagsContainer filterItem"
                onClick={() => {
                    if (graphic.isLoadingTags) {
                        return
                    }
                    setTypeFilter(FilterType.TAG)
                }}
            >
                <Label className="iconFilter" />
                <p>Tag {user.selectedTags && user.selectedTags.length > 0 && "(selezionate: " + user.selectedTags.length + ")"}</p>
                {graphic.isLoadingTags && (
                    <div className="loaderIcon">
                        <LoadingIcon />
                    </div>
                )}
            </div>
            <p
                className="resetFiltersButton filterItem"
                style={{
                    opacity: user.selectedQuery == "" && user.selectedRegions.length == 0 && user.selectedSources.length == 0 && user.selectedTags.length == 0 ? 0.6 : 1
                }}
                onClick={() => {
                    dispatch(cleanFilter())
                    setQuerySearch("")
                }}
            >
                Cancella filtri
            </p>
            {isMobile && (
                <p style={{ color: "#fff", textAlign: "center" }} onClick={() => props?.hideBar()}>Nascondi filtri</p>
            )}
        </div>
    )
}

export default FilterBar