import { Region, Source, Tag } from '../../types/data';
import { ReduxAction } from './../../types/reducers';

export enum USER_ACTIONS {
    SET_SELECTED_QUERY = "userActions/setSelectedQuery",
    SET_SELECTED_SOURCES = "userActions/setSelectedSources",
    SET_SELECTED_REGIONS = "userActions/setSelectedRegions",
    SET_SELECTED_TAGS = "userActions/setSelectedTags",
    CLEAN_FILTER = "userActions/clearFilter"
}

export const setSelectedSources: ReduxAction<Source[]> = (sources: Source[]) => {
    return {
        type: USER_ACTIONS.SET_SELECTED_SOURCES,
        payload: sources
    }
}

export const setSelectedRegions: ReduxAction<Region[]> = (regions: Region[]) => {
    return {
        type: USER_ACTIONS.SET_SELECTED_REGIONS,
        payload: regions
    }
}

export const setSelectedTags: ReduxAction<Tag[]> = (tags: Tag[]) => {
    return {
        type: USER_ACTIONS.SET_SELECTED_TAGS,
        payload: tags
    }
}

export const setSelectedQuery: ReduxAction<string> = (query: string) => {
    return {
        type: USER_ACTIONS.SET_SELECTED_QUERY,
        payload: query
    }
}

export const cleanFilter = () => {
    return {
        type: USER_ACTIONS.CLEAN_FILTER
    }
}

