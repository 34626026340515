import React, { useEffect } from 'react';
import './App.css';
import { isMobile } from 'react-device-detect'
import MainMobile from './pages/MainMobile'
import MainDesktop from './pages/MainDesktop'
import { useDispatch, useSelector } from 'react-redux';
import { getNews, getRegions, getSources, getTags } from './store/actions/thunk_actions';
import { Reducers, ReducerUser } from './types/reducers';
import { setCurrentPage, setOffset } from './store/actions/graphic';
import { resetNews } from './store/actions/data';
import { usePrevious } from './Utils/Functions';

const App: React.FC = () => {
  const dispatch = useDispatch()
  const user: ReducerUser = useSelector((store: Reducers) => store.user)

  useEffect(() => {
    startFetching()
  }, [
    user.selectedQuery,
    user.selectedRegions,
    user.selectedSources,
    user.selectedTags
  ])

  setInterval(() => startFetching(), 300000)

  const startFetching = () => {
    dispatch(resetNews())
    dispatch(setCurrentPage(1))
    dispatch(setOffset(0))
    dispatch(getNews())
  }

  useEffect(() => {
    dispatch(getSources())
    dispatch(getRegions())
    dispatch(getTags())
  }, [])

  return (
    <div>
      {isMobile ? <MainMobile /> : <MainDesktop />}
    </div>
  );
}

export default App;
