import React from 'react'
import { KeyboardArrowUpRounded } from '@material-ui/icons';

const GoTopButton:React.FC = () => {
    return (
        <div 
            className="goTopButton" 
            onClick={() => {
                window.scrollTo({top: 0, behavior: "smooth"})
            }}
        >
            <KeyboardArrowUpRounded className="goTopIcon" />
        </div>
    )
}

export default GoTopButton