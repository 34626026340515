export const LOGO =
  "https://22hbgtest.s3.eu-west-2.amazonaws.com/app_res/uspi/logo.png";

export const SOURCE_API = "https://api2.informazioneitaliana.info/client/feeds";

export const TAG_API = "https://api2.informazioneitaliana.info/client/tags";

export const REGION_API =
  "https://api2.informazioneitaliana.info/client/regions";

export const FILTER_API =
  "https://api2.informazioneitaliana.info/client/contents/filter";
