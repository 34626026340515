import {
  setCurrentPage,
  setIsFetchingNews,
  setIsLoadingRegions,
  setIsLoadingTags,
  setIsLoadingSources,
  setOffset,
} from "./graphic";
import axios from "axios";
import moment from "moment";
import {
  REGION_API,
  SOURCE_API,
  TAG_API,
  FILTER_API,
} from "../../resources/constants";
import { Filter, News, Tag } from "../../types/data";
import { Reducers } from "../../types/reducers";
import { setNews, setRegions, setSources, setTags } from "./data";
require("moment/min/locales");
moment.locale("it");

export const getTags: any = () => async (dispatch: (any: any) => void) => {
  const tags: Tag[] = await axios.get(TAG_API).then((res) => {
    return res.data.items;
  });
  console.log(tags);
  dispatch(setTags(tags));
  dispatch(setIsLoadingTags(false));
};

export const getRegions: any = () => async (dispatch: (any: any) => void) => {
  const regions = await axios.get(REGION_API).then((res) => {
    return res.data.items;
  });
  dispatch(setRegions(regions));
  dispatch(setIsLoadingRegions(false));
};

export const getSources: any = () => async (dispatch: (any: any) => void) => {
  const sources = await axios.get(SOURCE_API).then((res) => {
    return res.data.items;
  });
  dispatch(setSources(sources));
  dispatch(setIsLoadingSources(false));
};

export const getNews: any =
  () => async (dispatch: (any: any) => void, getState: () => Reducers) => {
    dispatch(setIsFetchingNews(true));
    const user = getState().user;
    const graphic = getState().graphic;
    const data = getState().data;
    const filter: Filter = {
      filter: {
        id_sources: user.selectedSources.map((s) => {
          return s._id;
        }),
        id_regions: user.selectedRegions.map((r) => {
          return r._id;
        }),
        id_tags: user.selectedTags.map((t) => {
          return t._id;
        }),
        query: user.selectedQuery,
      },
      bounds: {
        offset: graphic.offset,
        items: 10,
      },
    };
    //console.log(filter);
    const news = await axios.post(FILTER_API, filter).then((res) => {
      return res.data.items
        .map((n: News) => {
          return {
            ...n,
            content: n.content
              ? n.content.replace(/(<([^>]+)>)/gi, "")
              : n.content,
            feed: n.feed,
            summary: n.summary
              ? n.summary.replace(/(<([^>]+)>)/gi, "")
              : n.summary,
            tags: n.tags,
            date_parsed: moment(n.date).format("DD MMMM YYYY HH:mm"),
          };
        })
        .filter(
          (n: News) => data.news.find((o) => o._id == n._id) == undefined
        );
    });
    await dispatch(setNews(news));
    dispatch(setOffset(data.news.length + news.length));
    dispatch(setIsFetchingNews(false));
  };
