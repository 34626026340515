import React from "react";
import { LOGO } from "../resources/constants";
import MasonryGrid from "../components/MasonryGrid";
import FilterBar from "../components/FilterBar";
import GoTopButton from "../components/GoTopButton";

const MainDesktop: React.FC = () => {
  return (
    <div className="mainContainer">
      <img
        src={LOGO}
        className="logo"
        onClick={() => window.location.reload()}
      />
      <p className="filterTitle">Personalizza la tua esperienza</p>
      <FilterBar
        hideBar={() => {
          return;
        }}
      />
      <MasonryGrid />
      <GoTopButton />
    </div>
  );
};

export default MainDesktop;
