import React from 'react'
import './LoadingIcon.css'

const LoadingIcon: React.FC = () => {
    return (
        <div style={{ height: 30 }}>
            <div className="loadingio-spinner-dual-ring-2gao2qa5lot"><div className="ldio-ma5sgw1hvbe">
                <div></div><div><div></div></div>
            </div></div>
        </div>
    )
}

export default LoadingIcon