import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage } from '../store/actions/graphic'
import { getNews } from '../store/actions/thunk_actions'
import { ReducerData, ReducerGraphic, Reducers } from '../types/reducers'
import NewsContainer from './NewsContainer'
import InfiniteScroll from 'react-infinite-scroll-component'
import { resizeAllMasonryItems } from '../Utils/Functions'
import Loader from './Loader/Loader'
import { isMobile } from 'react-device-detect'
import InfiniteLoader from './InfiniteLoader'

const MasonryGrid: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const { innerWidth, innerHeight } = window
    const dispatch = useDispatch()

    const fetchNewNews = () => {
        dispatch(getNews())
    }

    useEffect(() => {
        if (!isMobile) {
            resizeAllMasonryItems()
        }
    }, [data.news])

    // if (data.news.length == 0) {
    //     return (
    //         <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "#fff", marginTop: 50 }}>
    //             <Loader />
    //             <h3>Sto scaricando le news...</h3>
    //         </div>
    //     )
    // }

    if (isMobile) {
        return (
            <InfiniteLoader loadMore={() => fetchNewNews()}>
                {data.news.map((n, i) => {
                    return (<NewsContainer item={n} index={i} />)
                })}
            </InfiniteLoader>
        )
    }

    return (
        // <InfiniteScroll
        //     dataLength={data.news.length}
        //     next={() => fetchNewNews()}
        //     hasMore={true}
        //     loader={<div style={{ marginTop: 20, textAlign: "center" }}><Loader /></div>}
        // >
        <InfiniteLoader loadMore={() => fetchNewNews()}>
            <div className="masonryGrid" style={{ gridAutoRows: 2, }} >
                {data.news.map((n, i) => {
                    return (<NewsContainer item={n} index={i} key={i.toString()} />)
                })}
            </div>
        </InfiniteLoader>
        //</InfiniteScroll>
    )
}

export default MasonryGrid