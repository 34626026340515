import { ReduxAction } from "./../../types/reducers";

export enum GRAPHIC_ACTIONS {
  SET_CURRENT_PAGE = "graphicActions/setCurrentPage",
  SET_OFFSET = "graphicActions/setOffset",
  SET_LOADING_REGIONS = "graphicActions/setLoadingRegions",
  SET_LOADING_TAGS = "graphicActions/setLoadingTags",
  SET_LOADING_SOURCES = "graphicActions/setLoadingSources",
  SET_LOADING_NEWS = "graphicActions/setLoadingNews",
  SET_FETCHING_NEWS = "graphicActions/setFetchingNews",
}

export const setCurrentPage: ReduxAction<number> = (number: number) => {
  return {
    type: GRAPHIC_ACTIONS.SET_CURRENT_PAGE,
    payload: number,
  };
};

export const setOffset: ReduxAction<number> = (number: number) => {
  return {
    type: GRAPHIC_ACTIONS.SET_OFFSET,
    payload: number,
  };
};

export const setIsLoadingRegions: ReduxAction<boolean> = (status: boolean) => {
  return {
    type: GRAPHIC_ACTIONS.SET_LOADING_REGIONS,
    payload: status,
  };
};

export const setIsLoadingTags: ReduxAction<boolean> = (status: boolean) => {
  return {
    type: GRAPHIC_ACTIONS.SET_LOADING_TAGS,
    payload: status,
  };
};

export const setIsLoadingSources: ReduxAction<boolean> = (status: boolean) => {
  return {
    type: GRAPHIC_ACTIONS.SET_LOADING_SOURCES,
    payload: status,
  };
};

export const setIsLoadingNews: ReduxAction<boolean> = (status: boolean) => {
  return {
    type: GRAPHIC_ACTIONS.SET_LOADING_NEWS,
    payload: status,
  };
};

export const setIsFetchingNews: ReduxAction<boolean> = (status: boolean) => {
  return {
    type: GRAPHIC_ACTIONS.SET_FETCHING_NEWS,
    payload: status,
  };
};
