import React from 'react'
import { News } from '../types/data'
import parse from 'html-react-parser';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton
} from "react-share";
import { Facebook, Twitter, LinkedIn, WhatsApp } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';

interface Props {
    item: News
    index: number
}

const NewsContainer: React.FC<Props> = (props) => {
    const news = props.item

    const renderTags = (tags: string[]) => {
        return (
            <div className="newsTags">
                {
                    tags.map((t, i) => {
                        if ((i < 3 || t.toLowerCase() == "uncategorized") && typeof t == "string") {
                            return (
                                <span key={i.toString()} >{t}</span>
                            )
                        } else {
                            return null
                        }
                    })
                }
            </div>
        )
    }

    const renderNewsSummary = (news: News) => {
        if (news.summary) {
            if (news.summary.length > 500) {
                return parse(news.summary.substring(0, 500) + "...")
            } else {
                return parse(news.summary)
            }
        }
        if (news.content) {
            if (news.content.length > 100) {
                return parse(news.content.substring(0, 100) + "...")
            } else {
                return parse(news.content)
            }
        }
        return null
    }

    return (
        <div className="newsContainer" key={news._id.toString()} >
            <div className="newsContent" onClick={() => window.open(news.url)}>
                <img
                    src={news.image ? news.image : news.feed.img}
                    style={{
                        height: isMobile ? 220 : 310,
                        width: "100%",
                        backgroundColor: "#fff"
                    }}
                />
                {news.tags !== null && news.tags.length > 0 && renderTags(news.tags)}
                <h4 className="titleNews">{parse(news.title)}</h4>
                <p className="metaNews">{news.feed.name} - <span style={{ textTransform: "capitalize" }}>{news.date_parsed}</span></p>
                <p className="newsSummary">{renderNewsSummary(news)}</p>
                <div className="shareNews">
                    <FacebookShareButton
                        url={news.url}
                        quote={"Leggi ora " + news.title + " di " + news.feed.name + " tramite UspiNews"}
                    >
                        <Facebook className="socialIcon" />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={news.url}
                        title={news.title + " - " + news.feed.name}
                        via="UspiNews"
                    >
                        <Twitter className="socialIcon" />
                    </TwitterShareButton>
                    <LinkedinShareButton
                        url={news.url}
                        title={news.title + " - " + news.feed.name}
                        summary={news.summary ? news.summary : news.content}
                        source={"UspiNews"}
                    >
                        <LinkedIn className="socialIcon" />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                        url={news.url}
                        title={news.title + " - " + news.feed.name}
                    >
                        <WhatsApp className="socialIcon" />
                    </WhatsappShareButton>
                </div>
            </div>
        </div>
    )
}

export default NewsContainer