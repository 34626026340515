import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx';
import { Source, Tag, Region } from '../types/data'
import { FilterType } from '../Utils/enum/FilterType'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ReducerData, Reducers, ReducerUser } from '../types/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { sortArray, usePrevious } from '../Utils/Functions'
import { Close, Search } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';
import { setSelectedQuery, setSelectedRegions, setSelectedSources, setSelectedTags } from '../store/actions/user';


interface Props {
    type: FilterType | null,
    onClose: () => void
}

const ModalFilter: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [selectedData, setSelectedData] = useState<any[]>([])
    const [queryFilter, setQueryFilter] = useState("")
    const dispatch = useDispatch()
    const classes = useStyles();

    const dialogData = useMemo(() => {
        switch (props.type) {
            case FilterType.SOURCE:
                setSelectedData([...user.selectedSources, ...selectedData])
                return {
                    title: "Filtra testate",
                    items: sortArray(data.sources).filter((i) => i.name.toLowerCase().includes(queryFilter))
                }
            case FilterType.REGION:
                setSelectedData([...user.selectedRegions, ...selectedData])
                return {
                    title: "Filtra regioni",
                    items: sortArray(data.regions).filter((i) => i.name.includes(queryFilter))
                }
            case FilterType.TAG:
                setSelectedData([...user.selectedTags, ...selectedData])
                return {
                    title: "Filtra tag",
                    items: sortArray(data.tags).filter((i) => i.name.includes(queryFilter))
                }
            default:
                setSelectedData([])
                setQueryFilter("")
                return {
                    title: " ",
                    items: []
                }
        }
    }, [props.type, queryFilter])


    const setItem = (item: Source | Tag | Region) => {
        if (selectedData.some(i => i._id == item._id)) {
            setSelectedData(selectedData.filter((i) => i._id !== item._id))
        } else {
            setSelectedData(selectedData.concat(item))
        }
    }

    const renderListItem = (item: Source | Tag | Region) => {
        const isSelected = selectedData.some(i => i._id == item._id)
        return (
            <div className="filterListItem" key={item._id.toString()} onClick={() => { setItem(item) }} >
                <Checkbox
                    checked={isSelected}
                    name="checkedF"
                    className={classes.root}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                />
                <span>{item.name}</span>
            </div>
        )
    }

    const renderSelectedItem = (item: Source | Tag | Region) => {
        return (
            <div
                className="selectedItem"
                key={item._id.toString()}
                onClick={() => {
                    removeItemAndFilter(item)
                }}
            >
                <Close />
                <span>{item.name}</span>
            </div>
        )
    }

    const removeItemAndFilter = (item: Source | Region | Tag) => {
        const newArray = selectedData.filter((i) => i._id !== item._id)
        setSelectedData(newArray)
        switch (props.type) {
            case FilterType.SOURCE:
                if (JSON.stringify(newArray) !== JSON.stringify(user.selectedSources)) {
                    dispatch(setSelectedSources(newArray))
                }
                return
            case FilterType.REGION:
                dispatch(setSelectedRegions(newArray))
                return
            case FilterType.TAG:
                dispatch(setSelectedTags(newArray))
                return
            default:
                return
        }
    }

    const setFilter = () => {
        switch (props.type) {
            case FilterType.SOURCE:
                dispatch(setSelectedSources(selectedData))
                props.onClose()
                return
            case FilterType.REGION:
                dispatch(setSelectedRegions(selectedData))
                props.onClose()
                return
            case FilterType.TAG:
                dispatch(setSelectedTags(selectedData))
                props.onClose()
                return
            default:
                props.onClose()
                return
        }
    }

    return (
        <Dialog
            fullWidth={true}
            open={props.type !== null}
            onClose={() => props.onClose()}
        >
            <DialogContent style={{ backgroundColor: "#343434", overflow: "hidden" }}>
                <Close style={{ top: 20, right: 20, position: "absolute", color: "#ccc", cursor: "pointer" }} onClick={() => props.onClose()} />
                <h2 style={{ color: "#fff", fontWeight: 400, marginTop: 0, marginBottom: 15 }}>{dialogData.title}</h2>
                <div className="searchItems">
                    <Search style={{ color: "#aaa" }} />
                    <input
                        type="text"
                        value={queryFilter}
                        onChange={(e) => setQueryFilter(e.target.value)}
                        placeholder="Cerca..."
                    />
                </div>
                {selectedData.length > 0 && (
                    <div className="selectedFilterHorizontal">
                        {selectedData.map((item) => {
                            return renderSelectedItem(item)
                        })}
                    </div>
                )}
                <div style={{ maxHeight: 400, overflowY: "scroll" }}>
                    {dialogData.items.map((item) => {
                        return renderListItem(item)
                    })}
                </div>
                <p className="buttonFilterDialog" onClick={() => setFilter()}>Filtra</p>
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

export default ModalFilter

