import React, { useState } from "react";
import FilterBar from "../components/FilterBar";
import MasonryGrid from "../components/MasonryGrid";
import { LOGO } from "../resources/constants";
import { FilterList } from "@material-ui/icons";
import GoTopButton from "../components/GoTopButton";

const MainMobile: React.FC = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <div className="mainContainer">
      <img
        src={LOGO}
        className="logo"
        onClick={() => window.location.reload()}
      />
      <p className="filterTitle">Personalizza la tua esperienza</p>
      {!isFilterVisible && (
        <p
          className="resetFiltersButton filterItem"
          onClick={() => {
            setIsFilterVisible(true);
          }}
          style={{ width: "80vw", marginTop: 0, marginBottom: 20, height: 54 }}
        >
          <FilterList style={{ color: "#fff" }} />
          Filtra
        </p>
      )}
      {isFilterVisible && (
        <FilterBar hideBar={() => setIsFilterVisible(false)} />
      )}
      <MasonryGrid />
      <GoTopButton />
    </div>
  );
};

export default MainMobile;
