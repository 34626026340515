import { useEffect, useRef } from "react";

export const sortArray = (array: any[]) => {
  const finalArray = array.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return finalArray;
};

/**
 * Set appropriate spanning to any masonry item
 *
 * Get different properties we already set for the masonry, calculate
 * height or spanning for any cell of the masonry grid based on its
 * content-wrapper's height, the (row) gap of the grid, and the size
 * of the implicit row tracks.
 *
 * @param item Object A brick/tile/cell inside the masonry
 */
const resizeMasonryItem = (item: any) => {
  /* Get the grid object, its row-gap, and the size of its implicit rows */
  let grid = document.getElementsByClassName("masonryGrid")[0],
    rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
    ),
    rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
    );

  /*
   * Spanning for any brick = S
   * Grid's row-gap = G
   * Size of grid's implicitly create row-track = R
   * Height of item content = H
   * Net height of the item = H1 = H + G
   * Net height of the implicit row-track = T = G + R
   * S = H1 / T
   */
  var rowSpan = Math.ceil(
    (item.querySelector(".newsContent").getBoundingClientRect().height +
      rowGap) /
      (rowHeight + rowGap)
  );

  /* Set the spanning as calculated above (S) */
  item.style.gridRowEnd = "span " + rowSpan;
};

/**
 * Apply spanning to all the masonry items
 *
 * Loop through all the items and apply the spanning to them using
 * `resizeMasonryItem()` function.
 *
 * @uses resizeMasonryItem
 */
export const resizeAllMasonryItems = () => {
  // Get all item class objects in one list
  let allItems = document.getElementsByClassName("newsContainer");

  /*
   * Loop through the above list and execute the spanning function to
   * each list-item (i.e. each masonry item)
   */
  for (var i = 0; i < allItems.length; i++) {
    resizeMasonryItem(allItems[i]);
  }
};

/**
 * Mantiene lo stato precendente del valore passato
 * @param value any
 */
export const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
