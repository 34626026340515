import { DATA_ACTIONS } from "./../actions/data";
import { ReducerData, ReduxActionData } from "../../types/reducers";

const defaultValue: ReducerData = {
  news: [],
  sources: [],
  regions: [],
  tags: [],
};

const dataState: (
  state: ReducerData,
  actions: ReduxActionData<any>
) => ReducerData = (state = defaultValue, action: ReduxActionData<any>) => {
  switch (action.type) {
    case DATA_ACTIONS.SET_NEWS:
      return {
        ...state,
        news: state.news.concat(action.payload),
      };
    case DATA_ACTIONS.SET_SOURCES:
      return {
        ...state,
        sources: action.payload,
      };
    case DATA_ACTIONS.SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case DATA_ACTIONS.SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case DATA_ACTIONS.RESET_NEWS:
      return {
        ...state,
        news: [],
      };
    default:
      return state;
  }
};

export default dataState;
