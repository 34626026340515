import React from 'react'
import './Loader.css'

const Loader: React.FC = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "#fff", marginTop: 20, height: 200 }}>
            <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3>Sto scaricando le news...</h3>
        </div>
    )
}

export default Loader